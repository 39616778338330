import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Button from '../../components/Button/Button';
// import Animal from '../../components/Animal/Animal';
import Instructor from '../../components/Instructor/Instructor';
import './LessonListItem.scss';
import ActivityCard from 'pages/HomePage/ActivityCard';
import StepRequirement from 'components/StepRequirement/StepRequirement';
import TodoPopup from 'components/Popups/TodoPopup/TodoPopup';

function LessonListItem({openLessonTodoPopup, index, lessonRequirements, activityPagePDF,activityPageThumb, pathname, size, state, isCurrent, title, description, imgPath, activityPagePath, AnimalType, instructorName, badge, stepsCount}){
    const {t} = useTranslation();
    const [showOverlayTip, setShowOverlayTip] = useState(false)
    const [overlayTipMsg, setOverlayTipMsg] = useState("")
    function handleOpenTodo () {
      openLessonTodoPopup(index)
    }
    const history = useHistory();

    const clickOnItem = () => {

      switch (state){
        case "locked":
          activateOvelrayTip(t("general.firstNeedToSubscribe"))
          break
        case "disabled":
          activateOvelrayTip(t("general.firstNeedToCompletePreviousActivity"))
        break
        default :
          history.push('/theatre/' + pathname)
        break
    }
  }

  const activateOvelrayTip = (msg) => {
    setShowOverlayTip(true)
    setOverlayTipMsg(msg)
    setTimeout(() => {
      setShowOverlayTip(false)
    }, 3000);
  }

    
    return(
        <>
        
       
               {/* (badge ? "LessonListItem__container--list-item__disabled badge_" + badge : 
                 (state=="disabled" ? " LessonListItem__container--list-item__disabled" : " ") + 
               (state=="completed" ? " LessonListItem__container--list-item__completed" : "") 
                 ) */}
        <div className="LessonListItem__main">

              <div onClick={handleOpenTodo} className={"LessonListItem__todo"}>
                <img src="assets/icons/icon_print-w.svg" alt="icon print" /> {t("onboarding.whatNeedForLesson")}
              </div>
              <div >
                  
                <a onClick={clickOnItem} >
                  
                <div className={[
                      "LessonListItem__container--list-item ",
                      ("LessonListItem__container_" + size),
                      (isCurrent && "LessonListItem__container_current "),
                      (state=="completed" && "LessonListItem__container_completed")
                      ].join(" ")}>

                      {/* {badge=="comingSoon" && <div className="Lesson_comingSoonBadge">{t("general.comingSoon")}</div>} */}
                              
                      {isCurrent && <div className='ArrowPointer'><img  src="/assets/icons/icon_arrowSelected.svg"/></div>}
                      {state === 'locked' && <img className="lock-sticker " alt={`Lesson complete!`} src={"/assets/icons/lock-sticker.svg"} />}
                      <div className={["lessonImg-container", (isCurrent && "backgroundGlow shadowGlow"), (state=="disabled" && "lessonImg-container-disabled "), (state === 'locked' && "lessonImg-container-locked")].join(" ")} >
                        {showOverlayTip && <div className="lessonImg-container_overlay-tip">{overlayTipMsg}</div>}
                        <img className={["lessonImg", (isCurrent && "backgroundGlow shadowGlow")].join(" ")}alt={`catbears language`} src={"/" + imgPath}/>
                        <div className={"LessonListItem__title " + (isCurrent && "LessonListItem__title-current")}>{title}</div>  
                        {/* <ActivityCard 
                            // name={"בובה על מקל"}
                            vidNum={stepsCount}
                            thumb_activitypage={"/" + activityPagePath}
                            thumb_img={"/" + imgPath}
                        /> */}
                        {state=="completed" && 
                            <div className="Lesson_Trophy  ">
                              <img className="bounceDelayed" alt={`Lesson complete!`} src={"/assets/icons/icon_trophy-no-shadow.svg"} />
                              <div className="glowCircle "></div>
                            </div>
                        }
                  </div>
                  <Instructor 
                    AnimalType ={AnimalType} instructorName ={instructorName}
                    intructorBottom = "-74px"
                    intructorSize = "180px"
                    intructorNameSize = "15px"
                    // intructorNameColor = "red"
                    intructorNameMargin = "0px"
                    isInlineStart={true}
                  />
                </div>
                </a>
              </div>
        </div>
    </>
    )
}


export default LessonListItem;

