import React , {useState} from 'react';
import {useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import Xbutton from "components/Xbutton";
import StepRequirement from 'components/StepRequirement/StepRequirement';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import './LessonLobby.scss'
import printFunc from 'utils/printFunc';
import FadeToBlackTransition from 'components/Transitions/FadeToBlackTransition';
import LessonOnboarding from './LessonOnboarding';


const LessonLobby = ({
            activityPagePDF,
            activityPageThumb,
            teaserThumbnail, 
            lessonImgURL,
            name, 
            description,
            instructorName,
            AnimalType,
            teaserUrl, 
            stepRequirements, 
            onContinue, 
            VolumeSwitchState,
            LessonStarted,
    }) => {
    const history = useHistory();

    const {t} = useTranslation();
    setPropertyVh();

    const onExit = () => {
        setVideoPlayer(false)
    }

    const onXbutton = () => {
        history.push('/theatre')
    }
 
    const handleOnPrint = () => {
        printFunc(activityPagePDF)
    }
    
    const [fadeToVideo, setFadeToVideo] = useState(false)
    const handleOnContinue = () => {
        LessonStarted ? onContinue() : setFadeToVideo(true)
    }
    
    const runOnContinue = () => {
        onContinue()
    }


    const onOpenVideo = () => {
            setVideoPlayer(true)
    }


    const [videoPlayer, setVideoPlayer] = useState(false)
    return (
        // <>
        // <FadeToBlackTransition 
        //     shouldStart={fadeToVideo}
        //     setShouldStart={setFadeToVideo}
        //     functionToFire={()=>setVideoPlayer(true)}
        //     duration={1400}
        // />
        // {videoPlayer && 
        //     <div className="lessonLobby-container--video">
        //         <VideoPlayer
        //             URL={teaserUrl}
        //             onExit={onExit}
        //             onContinue={LessonStarted ? onExit : onContinue}
        //             IsCurrentSelectedStepItemCompleted={true}
        //             volume={VolumeSwitchState}
        //             VideoPlayerLoop={false}
        //         />
        //   </div>
        //   }     
        //   {!videoPlayer &&  
          <>              
        
        <Xbutton fixed={true} onClose={onXbutton}/>
        {!LessonStarted && <LessonOnboarding 
            stepRequirements={stepRequirements}
            activityPagePDF={activityPagePDF}
            activityPageThumb={activityPageThumb}
            onFinish={runOnContinue}
            lessonImgURL={lessonImgURL}
            name={name}
            description={description}
            instructorName={instructorName}
            AnimalType={AnimalType}
            />}
        {LessonStarted && 
        <>              
            <div className={"Onboarding-container"}>
                <div className={"Onboarding-content hideScrollbar noselect"}>
                        <div className={"Onboarding-subtitle"}>{name}</div>
                        <div className={"Onboarding-title"}>{t("general.prepForLesson")}</div>
                        <div className="content-ab max-width-900">

                            {stepRequirements.map((Requirement, index) => {
                                    return (
                                        <div className="req-container " key={index}>
                                            <StepRequirement
                                                key={index}
                                                type={Requirement.name}
                                                showDescription
                                                imgPath={activityPageThumb}
                                                RadioButton={!LessonStarted}
                                                hideName={Requirement.name === "activityPage"}
                                                sizeM
                                                />
                                            {Requirement.name === "activityPage" && 
                                                <button onClick={handleOnPrint} className={"buttonCatbears buttonCatbears_CTA req-container_miniButton"}>
                                                    <img src="/assets/icons/icon-print.svg" />
                                                    {t("general.printActivityPages")}
                                                </button> 
                                            }
                                        </div>
                                    )
                                })}
                        </div>
                </div>
                <div className={"Onboarding-footer"}>
                    <button onClick={handleOnPrint} className={" buttonCatbears buttonCatbears_CTA "}>
                        <img src="/assets/icons/icon-print.svg" />
                        {t("general.printActivityPages")}
                    </button>          
                    <button onClick={handleOnContinue} className={" buttonCatbears "}>
                        {LessonStarted ? " ": <img src="/assets/icons/icon_play-circle-white.svg" />}
                        {LessonStarted ? t("general.backToLesson"): t("general.startLesson")}
                    </button>
                </div>
            </div>
        </>
        }
     </>
    )

}

export default LessonLobby